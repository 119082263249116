import React, { useEffect } from "react";

import { useAuth } from "../../../context/auth/useAuth";

// including sintax hightlight, install
// es6-string-html on vscode for a clear view
const SSOLogin = /*template*/ `
<!DOCTYPE html>
<html>
   <head>
      <title>Vodafone Business Cyberhub</title>
      <style>
         body {
         font-family: Vodafone Rg, Vodafone, Open Sans, sans-serif !important;
         }
         .com_vodafone_header {
         display:none !important;
         }
         .com_vodafone_body, .com_vodafone_border, .com_vodafone_box_0 {
         height:300px !important;
         width:380px !important;
         }
         .login, .login > div {
         height:380px 
         }
         .com_vodafone_footer {
         display:none !important;
         }
         .com_vodafone_box_0 > .com_vodafone_border { 
         background-color: #0d0d0d;
         width: 380px !important;
         height:300px;
         background-image:unset;
         }
         .com_vodafone_username,#com_vodafone_pwd,#com_vodafone_txt {
         transition: box-shadow 0.4s ease 0s;
         border: 1px solid rgb(126, 126, 126);
         border-radius: 6px;
         color: #afafaf !important;
         background-color:#262626;
         width: 90% !important;
         height: 26px !important;
         padding: 8px 12px !important;
         margin-bottom:8px;
         font-size: 18px !important;
         line-height: 26px;
         letter-spacing: 0px;
         }
         #com_vodafone_txt, #com_vodafone_pwd {
         top:70px;
         }
         .com_vodafone_username {
         position:initial !important;
         }
         .com_vodafone_button {
         display:inline-block !important;
         position:initial !important;
         }
         .com_vodafone_text {
         text-align: start !important;
         color: #fff;
         font-size: 18px;
         line-height: 18px !important;
         width: unset !important;
         }
         .btn { 
         cursor: pointer;
         position: absolute;
         left: 0;
         top: 140px;
         font-size: 18px;
         display: inline-flex;
         align-items: center;
         justify-content: center;
         box-sizing: border-box;
         text-align: center;
         transition: all 0.4s ease 0s;
         line-height: 12px;
         width: 97%;
         height: 44px;
         min-width: 152px;
         font-weight: 400;
         color: #fff !important;
         background: rgb(230, 0, 0);
         border-radius: 6px;
         padding: 12px 20px;
         border: 2px solid rgb(230, 0, 0);
         }
      </style>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <script type="text/javascript" src="https://login.vodafone.it/resources/ssoapi/js/ssologinbox.js"></script>
   </head>
   <body>
      <script type="text/javascript">
         function handleLoginFinished (token) {   
           const userInfo =  {
             user: com_vodafone_interface.username,
             firstName:com_vodafone_interface.user_nameComplete,
             lastName:com_vodafone_interface.user_surname,
             email: com_vodafone_interface.username
           }

           return window.parent.postMessage("logged:"+JSON.stringify({
              user:userInfo,
              isAuthenticated:true, 
              isExternal:true,
              externalInfo: {
               logoutUrl: com_vodafone_interface.logouturl
              }
            }), window.parent.origin);
          }
         
         com_vodafone_interface.site("${process.env.GATSBY_SITE_KEY}");
         
         com_vodafone_interface.loginbox("Vodafone Login");       
            
         com_vodafone_interface.callback = function(token) { 
             if (token) {   
               return handleLoginFinished();
             } else { 
               alert("Callback User not logged"); 
             } 
           };
           const form = document.querySelector('.login');
           const buttons = document.querySelectorAll('.com_vodafone_button');
         
           buttons.forEach(btn => {
             // Remove the class
             btn.classList.remove('com_vodafone_button');
         
             // Set the href attribute to an empty string
             btn.href = "";
         
             // Add a new class
             btn.classList.add('btn');
         
             // Remove the href attribute
             btn.removeAttribute("href");
         
             // Set the text content
             btn.textContent = 'Accendi';
           });
      </script>
   </body>
</html>
`;

export const SignInFormBox = () => {
  const { updateAuthState } = useAuth();
  const handleIframeMessage = (message: string) => {
    const identifiedMessage = message.split("logged:")[1];
    const parsed = JSON.parse(identifiedMessage);

    updateAuthState(parsed);
  };

  useEffect(() => {
    // Define a function to handle incoming messages from the iframe
    const handleMessage = (event: MessageEvent) => {
      const { data } = event;
      if (typeof data === "string" && data.includes("logged:")) {
        handleIframeMessage(data);
      }
    };

    // Define a function to handle the logic based on the received message

    // Add an event listener for the 'message' event
    window.addEventListener("message", handleMessage);

    // Clean up the event listener on component unmount to avoid memory leaks
    return () => {
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures useEffect runs only once

  return (
    <iframe
      id="ssoLoginBox"
      style={{
        width: "400px",
        height: "400px",
        borderWidth: 0
      }}
      title="Static Content"
      sandbox="allow-same-origin allow-top-navigation allow-modals allow-scripts allow-popups"
      srcDoc={SSOLogin}
    />
  );
};

export default SignInFormBox;
